
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import useWiseFetch from "@/composables/useWiseFetch";
import { BaseResponse } from "@/services/types";
import { getMemberLearningCategories } from "@/services/v1/member/learning";
import { LearningCategory } from "@/services/v1/member/learning/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  components: {},
  setup() {
    // START === setup breadcrumb ===========================================================
    const pathMemberMateri = `/member/materi`;
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Materi Pembelajaran",
        to: pathMemberMateri,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =============================================================

    // START === Function for get list learnings ============================================
    const {
      data: dataMateri,
      pending: pendingMateri,
      error: errorMateri,
      execute: executeMateri,
    } = useWiseFetch<BaseResponse<LearningCategory[]>>(() => {
      return getMemberLearningCategories();
    });

    const prettierLearnings = computed(() => {
      const resDataMateri = dataMateri.value?.response;
      return (
        resDataMateri?.map((learning: LearningCategory) => ({
          ...learning,
          toPageDetail: `${pathMemberMateri}/${learning.kode}`,
        })) || []
      );
    });

    const prettierErrorMessageFromFetchMateri = computed(() => {
      const messageErr = errorMateri.value?.data?.diagnostic?.message;
      return messageErr;
    });

    onMounted(() => {
      executeMateri();
    });
    // END === Function for get list learnings ==============================================

    return {
      prettierLearnings,
      pendingMateri,
      prettierErrorMessageFromFetchMateri,
    };
  },
});
